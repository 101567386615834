
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, MyCoachMixin, StringsMixin, BAIconsMixin, AthleteApiMixin, SportsSelectorMixin, ScoutingReportScoreSelectorMixin, CoachRoutingMixin } from "@/mixins";
import { AthleteReportModelEx, AthleteReportCategoryHeading } from "@/models/athleteReport/AthleteReportModel";
import { AthleteAutocompleteInfo } from '@best-athletes/ba-types';
import AthleteAutocomplete from "@/components/forms/AthleteAutocomplete.vue";
import { athleteReportApi } from "@/api/AthleteReportApi"
import { PlayerTypeValues, SportPosition } from '@/../types/enums'
import ScoreSelector from '@/components/forms/ScoreSelector.vue';
import { notificationStore } from "@/store";


@Component({
	components: {
		AthleteAutocomplete,
		ScoreSelector,
	}
})
export default class CoachAthleteReport extends Mixins(
		VuetifyMixin,
		StringsMixin,
		BAIconsMixin,
		MyCoachMixin,
		AthleteApiMixin,
		SportsSelectorMixin,
		ScoutingReportScoreSelectorMixin,
		CoachRoutingMixin,
	){
	@Prop() reportId: string;
	@Prop() athleteId: string;
	@Prop() teamId: string;
	@Prop() coachId: string;

	PlayerTypeValues = PlayerTypeValues;

	athleteReport: AthleteReportModelEx;

	isAthleteLoaded = false;
	isAthleteLoading = false;
	isAthleteLinking = false;
	athleteLink: AthleteAutocompleteInfo;

	isReportLoading: boolean = false;
	isReportLoaded: boolean = false;
	isReportChanging: boolean = false;
	isReportChanged: boolean = false;

	isYouth: boolean = true;

	mounted() {
		this.initializePage();
	}

	async initializePage() {
		await this.initializeReport();
		await this.loadReport();
	}
	async initializeReport() {
		this.coachId = this.MyCoachId;
		this.athleteReport = new AthleteReportModelEx(this.athleteId, this.teamId, this.coachId);
	}
	async loadReport() {
		this.isReportLoaded = false;
		this.isReportLoading = true;

		if( this.IsSavedReport ) {
			const foundReport = await athleteReportApi.findById( this.reportId );
			this.athleteReport = new AthleteReportModelEx().load(foundReport);
		}
		this.athleteId = this.athleteReport?.athleteId;
		this.teamId = this.athleteReport?.teamId;
		this.coachId = this.athleteReport?.coachId;
		
		await this.loadAthlete(this.athleteId);

		this.isReportLoading = false;
		this.isReportLoaded = true;
	}
	async loadAthlete(athleteId: string) {
		this.isAthleteLoaded = false;
		this.isAthleteLoading = true;

		this.athleteId = athleteId;
		this.athleteReport.athleteId = athleteId;
		await this.athleteReport.loadAthlete();

		this.isAthleteLoading = false;
		this.isAthleteLoaded = true;
	}

	isEditingReport: boolean = false;
	get IsSavedReport(): boolean {
		// if a report id is provided, the report was previously saved
		return this.IsNotEmpty( this.reportId );
	}
	get IsLoading(): boolean {
		// in process of loading some information from back-end
		return this.isReportLoading || this.isAthleteLoading || this.isAthleteLinking;
	}
	get IsAthleteLinked(): boolean {
		// if an athlete id is provided, athlete is linked to report
		return this.IsNotEmpty( this.athleteId );
	}

	get AthleteName(): string {
		if( !this.isAthleteLoaded ) return '';
		if( !this.athleteReport.HasAthleteProfile ) return '';

		return this.athleteReport.athlete.FullName;
	}

	async resetAthleteLink() {
		this.athleteId = undefined;
		this.teamId = undefined;

		this.initializeReport();
	}
	async onChangeAthleteLink(athleteLink: AthleteAutocompleteInfo) {
		this.isAthleteLinking = true;
		if( this.IsNotEmpty(this.athleteLink) && this.IsNotEmpty(this.athleteLink) && this.IsEmpty(athleteLink.id) ) {
			this.athleteLink.id = undefined;
		}
		await this.resetAthleteLink();
		await this.loadAthlete(athleteLink?.id);
		this.isAthleteLinking = false;
	}
	async onChangeJerseyNumber(number: string) {
		this.onReportChanged();
	}
	async onChangePosition(position: string) {
		this.onReportChanged();
	}
	async onChangeScore() {
		this.onReportChanged();
	}
	async onReportChanged() {
		this.isReportChanging = true;
		this.isReportChanged = true;
		this.isReportChanging = false;
	}

	async onExpandHeading(heading: AthleteReportCategoryHeading) {
		heading.expanded = !heading.expanded;
		this.onReportChanged();
	}
	async onNewAthleteReport() {
		await this.gotoCoachAthleteReport();
		await this.resetAthleteLink();
		await this.initializePage();
	}
	async onCancel() {
		if( this.isReportChanged ) {
			if( !confirm(`Are you sure you want to exit this form? Your changes will not be saved.`) ) return;
		}

		this.gotoCoachAthleteReportsSummary();
	}
	async onSave() {
		if( !this.isReportChanged ) return;

		this.athleteReport = new AthleteReportModelEx().load(this.athleteReport);
		if( this.IsEmpty(this.athleteReport.teamId) ) this.athleteReport.teamId = this.athleteReport.TeamName;
		await athleteReportApi.save(this.athleteReport);

		notificationStore.pushSnackbarSuccess({message: 'Athlete Report saved successfully'});
		this.gotoCoachAthleteReportsSummary();
	}
}
